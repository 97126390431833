import { useFormik } from "formik";
import { CaisseChequeResponseBody } from "../../../types/request/CaisseRequest";
import InputField from "../../../components/Inputs/InputField";
import { useTranslation } from "react-i18next";
import InputRadio from "../../../components/Inputs/InputRadio";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import { createChequeValidator } from "./validator";
import { useEffect, useState } from "react";
import { useCreateChequeTransaction, usePostQuote } from "../../../api/payments";
import Spinner from "../../../components/ui/Spinner";
import { CAISSETYPE } from "../../../constants";
import { useCaisse } from "./Provider";
import { TextMoneyFormat } from "../../../utils/TextMoneyFormat";
import toast from "react-hot-toast";
import { formatCreatedAtDate } from "../../../utils/formatDates";

export default function Cheque(): React.JSX.Element {
  const {
    maxAllowedNumberOfMonthsToPay,
    minAllowedNumberOfMonthsToPay,
    tenantName,
    tenantId,
    setTransactionRefrence,
    transactionRefrence,
    lastPaidDate,
  } = useCaisse();
  const { isLoading: isLoadingPayment, mutate: createChequeTransaction } = useCreateChequeTransaction({
    onSuccess() {
      toast.success(t("paymentSuccess"));
    },
  });
  const {
    isLoading: isLoadingQuote,
    data,
    mutate: getQuote,
  } = usePostQuote(tenantId, {
    onSuccess(data) {
      setTransactionRefrence(data.transactionReference);
    },
  });

  const [isOthers, setIsOthers] = useState<boolean>(false);

  const { t } = useTranslation();

  const isLoading: boolean = isLoadingPayment || isLoadingQuote;

  const initialValues: CaisseChequeResponseBody = {
    bank: "",
    chequeNumber: "",
    author: tenantName,
    months: NaN,
  };

  const formik = useFormik<CaisseChequeResponseBody>({
    initialValues,
    validationSchema: createChequeValidator(t, minAllowedNumberOfMonthsToPay, maxAllowedNumberOfMonthsToPay),
    onSubmit: values => {
      if (!transactionRefrence) return;

      createChequeTransaction({
        ...values,
        type: CAISSETYPE[0],
        tenantId: tenantId,
        numberOfMonths: values.months,
        transactionReference: transactionRefrence,
      });
    },
  });

  useEffect(() => {
    if (
      formik.values.months >= minAllowedNumberOfMonthsToPay &&
      formik.values.months <= maxAllowedNumberOfMonthsToPay
    ) {
      getQuote({
        type: CAISSETYPE[0],
        numberOfMonths: formik.values.months,
        ignoreDeposit: false,
        ignoreManagmentFees: false,
        ignoreRegisterFees: false,
      });
    }
  }, [formik.values.months, minAllowedNumberOfMonthsToPay, maxAllowedNumberOfMonthsToPay, getQuote]);

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="grid grid-cols-2 gap-8 px-10 pt-2 lg:px-16"
    >
      <InputField
        label="bank"
        name="bank"
        onChange={formik.handleChange}
        placeholder=""
        type="text"
        value={formik.values.bank}
        error={formik.errors.bank}
      />

      <InputField
        label="chequeNumber"
        name="chequeNumber"
        onChange={formik.handleChange}
        placeholder=""
        type="text"
        value={formik.values.chequeNumber}
        error={formik.errors.chequeNumber}
      />

      <InputField
        label="monthsToPay"
        name="months"
        onChange={formik.handleChange}
        placeholder=""
        type="number"
        value={formik.values.months}
        error={formik.errors.months}
      />

      {isOthers ? (
        <InputField
          name="author"
          label="fullName"
          onChange={formik.handleChange}
          placeholder=""
          type="text"
          value={formik.values.author}
          error={formik.errors.author}
        />
      ) : (
        <div />
      )}

      <section className="flex items-center justify-center gap-2">
        <span className="font-medium text-neutral-800">{t("period")} :</span>
        <span className="font-semibold text-primaryGreen">
          {formatCreatedAtDate(t, lastPaidDate)}
          {lastPaidDate &&
            formik.values.months > 0 &&
            " - " +
              formatCreatedAtDate(
                t,
                new Date(new Date(lastPaidDate).setMonth(new Date(lastPaidDate).getMonth() + formik.values.months))
                  .toISOString()
                  .replace(".000Z", ""),
              )}
        </span>
      </section>

      <section className="flex items-center gap-8">
        <InputRadio
          checked={formik.values.author === tenantName}
          name="author"
          onChange={() => {
            formik.setFieldValue("author", tenantName);
            setIsOthers(false);
          }}
          value="author"
          label="interesse"
        />
        <InputRadio
          checked={isOthers}
          name="author"
          onChange={() => {
            setIsOthers(true);
            formik.setFieldValue("author", "");
          }}
          value="qsd"
          label="other"
        />
      </section>

      <section className="col-span-2 flex flex-col items-center justify-center space-y-4">
        <div className="flex gap-4">
          <span className="text-2xl font-semibold">{t("netAPay")}</span>
          <span className="text-2xl font-semibold text-red-500">
            {isLoadingQuote ? <Spinner /> : TextMoneyFormat(data?.totalQuote || 0, t)}
          </span>
        </div>
        <PrimaryButton disabled={isLoading}>{isLoading ? <Spinner /> : t("saveRecu")}</PrimaryButton>
      </section>
    </form>
  );
}
