import { useTranslation } from "react-i18next";
import { useGetTenants } from "../../api/tenants";
import Spinner from "../../components/ui/Spinner";
import SearchTenantController from "./controller";
import PrimaryLink from "../../components/Buttons/PrimaryLink";
import { ReactComponent as DoubleChevronRightIcon } from "../../assets/icons/doubleChevronRight.svg";
import TenantTable from "./Table";
import { useAuth } from "../../contexts/Auth";
import { useSearchParams } from "react-router-dom";

export default function SearchTenant(): React.JSX.Element {
  const { selectedWorkspace } = useAuth();
  const { isLoading, data } = useGetTenants(selectedWorkspace?.workspaceId || "");
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const { response: tenants } = data ?? {};

  return (
    <div className="space-y-6">
      <SearchTenantController />
      {isLoading ? (
        <div className="flex h-96 items-center justify-center rounded-xl bg-white">
          <Spinner />
        </div>
      ) : tenants ? (
        <article className="space-y-5">
          <section className="flex items-center justify-between px-4">
            <h2 className="text-xl font-bold">{t("lastSearch")}</h2>
            <PrimaryLink
              to={"/dashboard/tenants?q=" + searchParams.get("q")}
              className="flex items-center gap-2"
            >
              {t("viewAll")}
              <DoubleChevronRightIcon className="rtl:rotate-180" />
            </PrimaryLink>
          </section>
          <TenantTable tenants={tenants} />
        </article>
      ) : (
        <div className="flex h-96 items-center justify-center rounded-xl bg-white">
          <p>Something went wrong</p>
        </div>
      )}
    </div>
  );
}
