import { useTranslation } from "react-i18next";
import { useGetTenants } from "../../api/tenants";
import TenantTable from "../Search/Table";
import Spinner from "../../components/ui/Spinner";
import Pagination from "../../components/Pagination";
import { useSearchParams } from "react-router-dom";
import { rowsPerPage } from "../../utils/rowsPerPage";
import { useAuth } from "../../contexts/Auth";

export default function Tenants(): React.JSX.Element {
  const { selectedWorkspace } = useAuth();
  const { isLoading, data } = useGetTenants(selectedWorkspace?.workspaceId || "");
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const { response: tenants, pageCount } = data ?? {};
  const currentPage = Math.max(Number(searchParams.get("page")) || 1, 1);

  return (
    <article className="space-y-6">
      <div>
        {searchParams && searchParams.get("q") ? (
          <section className="flex gap-1 text-sm text-gray-500">
            <span>{t("search")}</span>
            <span>{":"}</span>
            <span>{searchParams.get("q")}</span>
          </section>
        ) : null}
        <section>
          <h1 className="text-2xl font-bold">{t("tenants")}</h1>
          <p className="text-lg text-primaryGray">{t("signupUser")}</p>
        </section>
      </div>
      {isLoading ? (
        <section className="flex h-96 items-center justify-center rounded-xl bg-white">
          <Spinner />
        </section>
      ) : tenants && tenants.length > 0 ? (
        <TenantTable tenants={tenants} />
      ) : (
        <section className="flex h-96 items-center justify-center rounded-xl bg-white">NoData</section>
      )}
      {pageCount ? (
        <Pagination
          currentPage={currentPage}
          totalPages={Math.ceil(pageCount / rowsPerPage)}
        />
      ) : null}
    </article>
  );
}
