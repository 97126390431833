import { useEffect } from "react";
import { useAuth } from "../../contexts/Auth";

export default function NotAuthorized(): React.JSX.Element {
  const { logout } = useAuth();

  useEffect(() => {
    logout();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div>Not authorized</div>;
}
