import { createContext, useContext, useState } from "react";
import { LoginRequest } from "../types/request/LoginRequest";
import { useLogin, useLogout } from "../api/user";
import { ErrorResponse } from "../types/response";
import { UseMutationResult } from "react-query";
import { UserData } from "../types/data/user";
import { LoginResponse } from "../types/response/LoginResponse";

interface AuthContextType {
  userData: UserData;
  linkedWorkspaces: ILinkedWorkspace[] | null;
  selectedWorkspace: ILinkedWorkspace | null;
  permissions: Record<string, string>;
  isAuthentificated: boolean;
  login: UseMutationResult<LoginResponse, ErrorResponse, LoginRequest, unknown>;
  logout: () => void;
}

export interface ILinkedWorkspace {
  workspaceId: string;
  workspaceDescription: string;
  workspaceName: string;
}

export const AuthContext = createContext<AuthContextType | null>(null);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<UserData>(
    JSON.parse(
      localStorage.getItem("userData") ||
        JSON.stringify({
          email: "",
          phone: "",
          type: "guest",
          username: "",
        }),
    ) || {
      email: "",
      phone: "",
      type: "guest",
      username: "",
    },
  );
  const [permissions, setPermissions] = useState<Record<string, string>>(
    JSON.parse(localStorage.getItem("permissions") || "{}"),
  );
  const [linkedWorkspaces, setLinkedWorkspaces] = useState<ILinkedWorkspace[] | null>(
    JSON.parse(localStorage.getItem("linkedWorkspaces") || "[]") || null,
  );
  const [selectedWorkspace, setSelectedWorkspace] = useState<ILinkedWorkspace | null>(
    JSON.parse(localStorage.getItem("selectedWorkspace") || "null") || null,
  );
  const [isAuthentificated, setIsAuthentificated] = useState<boolean>(!!localStorage.getItem("token"));

  const login = useLogin({
    onSuccess: data => {
      const { token, username, permissions, linkedWorkspaces } = data;

      setUser({
        username,
        email: "",
        phone: "",
        type: "admin",
      });

      setPermissions(permissions);

      const sortedWorkspaces = linkedWorkspaces.sort((a, b) =>
        a.workspaceDescription.localeCompare(b.workspaceDescription),
      );

      setSelectedWorkspace(sortedWorkspaces[0] || null);
      setLinkedWorkspaces(sortedWorkspaces);

      localStorage.setItem("token", token);
      localStorage.setItem(
        "userData",
        JSON.stringify({
          username,
          email: "",
          phone: "",
          type: "admin",
        }),
      );
      localStorage.setItem("permissions", JSON.stringify(permissions));
      localStorage.setItem("linkedWorkspaces", JSON.stringify(sortedWorkspaces));
      localStorage.setItem("selectedWorkspace", JSON.stringify(sortedWorkspaces[0] || null));
      setIsAuthentificated(true);
    },
  });

  const { mutate: logout } = useLogout();

  return (
    <AuthContext.Provider
      value={{
        isAuthentificated,
        userData: user,
        permissions,
        linkedWorkspaces,
        selectedWorkspace,
        login,
        logout: () => {
          logout(localStorage.getItem("token") || "");
          localStorage.removeItem("token");
          localStorage.removeItem("userData");
          localStorage.removeItem("permissions");
          localStorage.removeItem("linkedWorkspaces");
          localStorage.removeItem("selectedWorkspace");
          setUser({
            email: "",
            phone: "",
            type: "guest",
            username: "",
          });
          setPermissions({});
          setLinkedWorkspaces(null);
          setSelectedWorkspace(null);
          setIsAuthentificated(false);
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) throw new Error("AuthContext is not available. Make sure you wrap your component with AuthProvider.");

  return context;
}
